'use client';

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { format, isSameDay } from 'date-fns';
import { Calendar as CalendarIcon, Loader2 } from 'lucide-react';
import { useTranslations } from 'next-intl';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useState, useTransition } from 'react';
import { cities } from '@/constants/cities';
import { useRouter } from 'next/navigation';
import { getCurrentTimeFilteredTimes } from '@/constants/times';
import { defaultSportTypes } from '@/constants/sport_types';
const removeFocusRing = {
  outline: 'none',
  '--tw-ring-offset-width': '0px',
  '--tw-ring-opacity': '0'
};
export default function NewHeroSearch({
  className,
  searchedTimeSlot,
  date: defaultDate,
  sport: defaultSport
}: {
  date?: string;
  sport?: string;
  className?: string;
  searchedTimeSlot?: string;
}) {
  const t = useTranslations('home_page.search');
  const sportsT = useTranslations('sports');
  const [isPending, startTransition] = useTransition();
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [date, setDate] = useState<Date>(defaultDate ? new Date(defaultDate) : new Date());
  const filteredTimes = getCurrentTimeFilteredTimes(date);
  const router = useRouter();
  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const city = formData.get('city') as string;
    const sport = formData.get('sport') as string;
    const time = formData.get('time') as string;
    if (!city || !sport || !date || !time) {
      return;
    }
    const formattedDate = format(date, 'yyyy-MM-dd');
    const searchParams = new URLSearchParams({
      city,
      sport,
      date: formattedDate,
      time
    });
    startTransition(() => {
      router.push(`/search?${searchParams.toString()}`);
    });
  };
  const defaultTimeSlot = searchedTimeSlot && filteredTimes.includes(searchedTimeSlot) ? searchedTimeSlot : filteredTimes[0];
  return <form className={className} onSubmit={onFormSubmit} data-sentry-component="NewHeroSearch" data-sentry-source-file="NewHeroSearch.tsx">
      <div className="flex flex-col gap-4 w-full">
        <Select name="city" defaultValue={'1'} data-sentry-element="Select" data-sentry-source-file="NewHeroSearch.tsx">
          <SelectTrigger className={`w-full`} style={removeFocusRing} data-sentry-element="SelectTrigger" data-sentry-source-file="NewHeroSearch.tsx">
            <SelectValue placeholder={t('city')} defaultValue={t('city')} data-sentry-element="SelectValue" data-sentry-source-file="NewHeroSearch.tsx" />
          </SelectTrigger>
          <SelectContent data-sentry-element="SelectContent" data-sentry-source-file="NewHeroSearch.tsx">
            {Object.entries(cities).map(([id, city]) => <SelectItem key={id} value={id}>
                {city}
              </SelectItem>)}
          </SelectContent>
        </Select>
        <Select name="sport" defaultValue={defaultSport || defaultSportTypes[0]} data-sentry-element="Select" data-sentry-source-file="NewHeroSearch.tsx">
          <SelectTrigger className={`w-full`} style={removeFocusRing} data-sentry-element="SelectTrigger" data-sentry-source-file="NewHeroSearch.tsx">
            <SelectValue placeholder={t('sport')} defaultValue={defaultSport || defaultSportTypes[0]} data-sentry-element="SelectValue" data-sentry-source-file="NewHeroSearch.tsx" />
          </SelectTrigger>
          <SelectContent data-sentry-element="SelectContent" data-sentry-source-file="NewHeroSearch.tsx">
            {defaultSportTypes.filter(sp => sp === 'squash' || sp === 'padel').map(sport => <SelectItem key={sport} value={sport}>
                  {sportsT(sport)}
                </SelectItem>)}
          </SelectContent>
        </Select>
        <Popover open={calendarOpen} onOpenChange={setCalendarOpen} data-sentry-element="Popover" data-sentry-source-file="NewHeroSearch.tsx">
          <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="NewHeroSearch.tsx">
            <Button variant={'outline'} className={cn('w-full justify-start text-left font-normal', !date && 'text-muted-foreground')} data-sentry-element="Button" data-sentry-source-file="NewHeroSearch.tsx">
              <CalendarIcon className="mr-2 h-4 w-4" data-sentry-element="CalendarIcon" data-sentry-source-file="NewHeroSearch.tsx" />
              {date ? format(date, 'dd.MM.yyyy') : <span>{t('choose_date')}</span>}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" data-sentry-element="PopoverContent" data-sentry-source-file="NewHeroSearch.tsx">
            <Calendar mode="single" selected={date} onSelect={d => {
            if (!d) return;
            setDate(d);
            setCalendarOpen(false);
          }} disabled={d => {
            if (isSameDay(d, new Date())) return false;
            return d < new Date();
          }} data-sentry-element="Calendar" data-sentry-source-file="NewHeroSearch.tsx" />
          </PopoverContent>
        </Popover>
        <Select name="time" defaultValue={defaultTimeSlot} data-sentry-element="Select" data-sentry-source-file="NewHeroSearch.tsx">
          <SelectTrigger className={`w-full`} style={removeFocusRing} data-sentry-element="SelectTrigger" data-sentry-source-file="NewHeroSearch.tsx">
            <SelectValue placeholder={t('time')} data-sentry-element="SelectValue" data-sentry-source-file="NewHeroSearch.tsx" />
          </SelectTrigger>
          <SelectContent data-sentry-element="SelectContent" data-sentry-source-file="NewHeroSearch.tsx">
            {filteredTimes.map(time => <SelectItem key={time} value={time}>
                {time}
              </SelectItem>)}
          </SelectContent>
        </Select>
      </div>
      <Button disabled={isPending} type="submit" className="w-full mt-6" data-sentry-element="Button" data-sentry-source-file="NewHeroSearch.tsx">
        {isPending ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : t('search_button')}
      </Button>
    </form>;
}